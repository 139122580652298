<template>
  <v-app style="background-color: #eaeaea">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar" v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app>
        <center class="avatar_container mt-10 mb-10">
          <div class="avatar">
            <img src="@/assets/logo_white.png" width="200" />
          </div>

          <v-avatar size="75" v-if="user.profile_picture">
            <v-img width="75" :src="user.profile_picture" cover></v-img>
          </v-avatar>

          <h5 class="icon mt-4">{{ user.name }}</h5>
        </center>
        <v-divider />

        <v-list dense>
          <router-link to="/">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-monitor-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/central-educativa" v-if="user.admin">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-school-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Central educativa</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>


          <!--
          <router-link to="/catalogo">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-list-box-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Catálogo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          -->

          <router-link to="/testes">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-test-tube</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item" v-if="user.admin">Testes realizados</v-list-item-title>
                <v-list-item-title class="menu-item" v-if="!user.admin">Meus Testes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/importar-testes">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-file-import-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Importar testes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/perfil">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Meu perfil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-divider v-if="user.admin" />

          <v-list-item class="menu-label" v-if="user.admin && !user.consultant">Administração</v-list-item>

          <v-list-group class="mt-2" v-if="user.admin && !user.consultant">
            <template #activator>
              <v-list-item-icon>
                <span class="mr-3">
                  <v-icon color="white" class="icon">mdi-account-group-outline</v-icon>
                </span>
              </v-list-item-icon>

              <v-list-item-title class="menu-item">
                Central Educativa
              </v-list-item-title>
            </template>

            <v-list-item to="/categorias-central-educativa" class="menu-item">
              <v-list-item-icon>
                <span class="mx-3">
                  <v-icon color="white" class="icon">mdi-tag-multiple-outline</v-icon>
                </span>
              </v-list-item-icon>

              <v-list-item-title class="menu-item">Categorias</v-list-item-title>
            </v-list-item>

            <v-list-item to="/edicao-central-educativa" class="menu-item">
              <v-list-item-icon>
                <span class="mx-3">
                  <v-icon color="white" class="icon">mdi-file-document-edit-outline</v-icon>
                </span>
              </v-list-item-icon>

              <v-list-item-title class="menu-item">Conteúdo</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <router-link to="/testes-imunocromatografia" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon" style="transform: rotate(90deg);">mdi-minus-thick</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Testes de imunocromatografia</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/analises-fisico-quimicas" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-beaker-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Análises físico-químicas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/faq" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-frequently-asked-questions</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">FAQ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/politica-de-privacidade" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-lock-check-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Política de privacidade</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/notificacoes" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-send-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Notificações push</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <router-link to="/usuarios" v-if="user.admin && !user.consultant">
            <v-list-item link>
              <v-list-item-action class="item_icon">
                <v-icon class="icon">mdi-account-multiple-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="menu-item">Usuários</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ projectName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="cursor: pointer" @click="$router.push('/perfil')">
                <v-list-item-title>Meu perfil</v-list-item-title>
              </v-list-item>
              <v-list-item style="cursor: pointer" @click="logout()">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    projectName: "Plataforma Somaticell",
    user: {},
  }),

  methods: {
    logout() {
      Api.logout();
    },

    setLoading(loading) {
      this.showLoading = loading;
    },

    updateUser(user) {
      this.user = user;
    }
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    const user = Api.getUser();
    if (user?._id) this.user = await Api.getRemoteUser();

    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.menu-page-container {
  background-color: #eaeaea;
}

.toolbar {
  font-weight: 700;
  color: black;
}

.drawer {
  background: rgb(0, 0, 0);
  background: linear-gradient(145deg, #000000 0%, #08080f 5%, #1e3c72 30%, #2a5298 96%);
  z-index: 100;
}

.icon {
  color: white;
}

.item_icon {
  margin-right: 16px !important;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-item {
  color: white;
  font-weight: 700 !important;
  text-overflow: none !important;
}

.menu-label {
  color: white !important;
  font-weight: 700 !important;
  font-size: 12px;
  text-transform: uppercase;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}
</style>
