
import Vue from "vue";
import Api from "@/lib/Api";
import * as utils from "@/lib/Utils";

Vue.prototype.$toast = utils.emitToastr;
Vue.prototype.$api = Api;
Vue.prototype.$utils = utils;

Vue.prototype.$rules = {
    required: (message = "Campo Obrigatório") => {
        return v => !!v || message;
    },
    minLength: (min, message = "Campo inválido") => {
        return v => {
            if (!v) {
                return true
            }

            return v.length >= min || message;
        }
    },
    regex: (pattern, message = "Campo inválido") => {
        return v => {
            if (!v) {
                return true
            }

            return pattern.test(v) || message;
        }
    },
    sameAs: (field, message = "Campo inválido") => {
        return v => {
            if (!v) {
                return true
            }

            return v === field || message;
        }
    }
}