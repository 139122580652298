import axios from "axios";
import { emitToastr } from "./Utils";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:3005/admin" : "https://app.somaticell.com.br/admin";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async () => {
    const user = await this.authPost("/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  uploadImage = async (image) => {
    const resp = await this.authPost("/upload-image-s3", { image }, { multipart: true });
    return resp;
  };

  insertTestImageUrl = async (cloudId, imageUrl) => {
    const resp = await this.authPost("/insert-test-image-url", { cloudId, imageUrl }, {});
    return resp;
  };

  getUsers = async () => {
    const resp = await this.authPost("/get-users", {}, {});
    return resp;
  };

  createOrUpdateUser = async (user) => {
    const resp = await this.authPost("/create-or-update-user", { user }, {});
    return resp;
  };

  changeUserData = async (user) => {
    const resp = await this.authPost("/change-user-data", { name: user.name, business_name: user.business_name, email: user.email, profile_picture: user.profile_picture }, {});
    return resp;
  };

  recoveryPassword = async (email) => {
    const resp = await this.authPost("/send-password-recovery", { email: email }, {});
    return resp;
  };

  changePassword = async (old_password, password) => {
    const resp = await this.authPost("/change-user-password", { old_password, password }, {});
    return resp;
  };

  checkErrorsWithStatusCode200 = (data) => {
    if (data.noHandle) return data;
    if (data.error) return emitToastr("error", data.message || "Erro não identificado!", "Erro");
    return data;
  };

  getCatalogs = async () => {
    const resp = await this.authPost("/get-catalogs", {}, {});
    return resp;
  };

  createOrUpdateCatalog = async (object) => {
    const resp = await this.authPost("/create-or-update-catalog", { object }, {});
    return resp;
  };

  getStripTests = async () => {
    const resp = await this.authPost("/get-strip-tests", {}, {});
    return resp;
  };

  createOrUpdateStripTest = async (object) => {
    const resp = await this.authPost("/create-or-update-strip-test", { object }, {});
    return resp;
  };

  getReagentTests = async () => {
    const resp = await this.authPost("/get-reagent-tests", {}, {});
    return resp;
  };

  createOrUpdateReagentTest = async (object) => {
    const resp = await this.authPost("/create-or-update-reagent-test", { object }, {});
    return resp;
  };

  getFaqs = async () => {
    const resp = await this.authPost("/get-faqs-by-admin", {}, {});
    return resp;
  };

  createOrUpdateFaq = async (object) => {
    const resp = await this.authPost("/create-or-update-faq", { object }, {});
    return resp;
  };

  getPrivacyPolicy = async () => {
    const resp = await this.authPost("/get-privacy-policy", {}, {});
    return resp;
  };

  createPrivacyPolicy = async (object) => {
    const resp = await this.authPost("/create-privacy-policy", { object }, {});
    return resp;
  };

  getTests = async (query) => {
    const resp = await this.authPost("/get-tests", { query }, {});
    return resp;
  };

  uploadProfilePicture = async (image) => {
    const resp = await this.authPost("/upload-image", { image: image, type: 'profile_picture' }, { multipart: true, formData: true });
    return resp;
  };

  getDashboardData = async (interval) => {
    const resp = await this.authPost("/get-dashboard-data", { interval }, {});
    return resp;
  };

  getNotifications = async () => {
    const resp = await this.authPost("/get-notifications", {}, {});
    return resp;
  };

  createOrUpdateNotification = async (object) => {
    const resp = await this.authPost("/create-or-update-notification", { object }, {});
    return resp;
  };

  sendResultsList = async (data, file, save = false) => {
    const endpoint = '/import-test-results';
    const result = await this.authPost(endpoint, { ...data, file: file, save: save }, { multipart: true, formData: true });
    return result;
  }

  exportTests = async (data, headers, options) => {
    const endpoint = '/export-test-results';
    const result = await this.authPost(endpoint, { data, headers, options }, { arraybuffer: true });
    return result;
  }

  siteSignInOnUser = async (credential) => {
    const endpoint = '/site-sign-in-on-user';
    const result = await this.authPost(endpoint, { credential }, {});
    return result;
  }

  getAllEducationContent = async () => {
    return await this.authPost("/get-all-education-content");
  }

  getAllEducationContentCategories = async () => {
    return await this.authPost("/get-all-education-content-categories");
  }

  getVisibleEducationContent = async () => {
    return await this.authPost("/get-visible-education-content");
  }

  createOrUpdateEducationContent = async (payload) => {
    return await this.authPost("/create-or-update-education-content", payload);
  }
  createOrUpdateEducationContentCategory = async (payload) => {
    return await this.authPost("/create-or-update-education-content-category", payload);
  }

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers, responseType: options && options.arraybuffer ? 'arraybuffer' : undefined })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        console.error(err.response);
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return emitToastr("error", err.response.data.message);
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
