<template>
    <v-col cols="auto" md="4">
        <v-card class="card noselect white--text" min-width="250" max-width="250" min-height="400" max-height="400" :style="`background-image:url(${data.image})`" @click.prevent="open">
            <div class="overlay">
                <div class="card-container">
                    <v-card-title class="two-lines-text-card card-title">{{ data.title }} </v-card-title>
                    <v-card-text class="three-lines-text-card card-description"> {{ data.subtitle }} </v-card-text>


                    <v-card-actions class="card-buttons">
                        <div class="card-buttons-container">
                            <v-btn class="default-button access-button" color="primary" @click.stop="click(data)" page="card"> Acessar </v-btn>
                        </div>
                    </v-card-actions>
                </div>
            </div>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "CardObject",
    props: {
        data: { type: Object },
        click: { type: Function }
    },
    data() {
        return {

        };
    },
    components: {

    },

    async mounted() {

    },

    computed: {

    },

    methods: {
        open() {

        }
    },
};
</script>

<style scoped>
.two-lines-text-card {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    word-break: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

.three-lines-text-card {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    word-break: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
}

.card {
    min-width: 250px;
    max-width: 250px;
    min-height: 400px;
    max-height: 400px;
}

.card-container {
    width: 100%;
}

.card {
    box-sizing: border-box;
    border-radius: 4px !important;
    display: flex;
    align-items: flex-end;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: 0.4s;
}

.card:hover {
    transform: translateY(-8px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
}

.overlay {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    border-radius: 4px !important;
    display: flex;
    align-items: flex-end;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0)) !important;
    transition: 0.5s;
}

.overlay:hover {
    background-image: linear-gradient(to top, rgba(40, 77, 144, 0.95) 50%, rgba(0, 0, 0, 0)) !important;
}

.card-title {
    height: 52px;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    text-align: start;
    align-items: normal;
    text-shadow: 2px 2px 2px black;
}

.card-description {
    margin-top: 5px;
    padding-bottom: 0;
    height: 70px;
    font-size: 15px;
    line-height: 18px;
    text-align: start;
    text-shadow: 2px 2px 2px black;
}

.card-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 16px;
    bottom: 0;
}

.card-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.access-button {
    height: 35px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    width: auto;
}
</style>