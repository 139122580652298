<template>
    <div v-if="loaded">
        <div class="d-flex mb-6" v-if="heros.length" style="max-width: 1200px;">
            <v-responsive class="hero-container" :aspect-ratio="21 / 9">
                <v-carousel height="auto" cycle hide-delimiters hide-delimiter-background show-arrows-on-hover style="border-radius: 6px;">
                    <v-carousel-item v-for="(hero, index) in heros" contain :src="hero.featured_image" :aspect-ratio="21 / 9" :key="`hero_${index}`">
                        <div class="overlay">
                            <div class="hero-content-container">
                                <div class="hero-title two-lines-text-card">
                                    {{ hero.title }}
                                </div>
                                <div class="hero-description five-lines-text-card">
                                    {{ hero.subtitle }}
                                </div>
                                <div class="hero-button">
                                    <v-btn height="40" class="primary" page="course" @click="openContent(hero)">Acessar</v-btn>
                                </div>
                            </div>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </v-responsive>
        </div>

        <div class="content-container" v-for="category in categories" :key="category._id">
            <div class="category-container" v-if="category.content?.length">
                <div class="category-texts-container">
                    <div class="category-title">{{ category.title }}</div>
                    <div class="category-description">{{ category.subtitle }}</div>
                </div>
            </div>

            <vue-horizontal v-if="loaded" ref="myVueHorizontal" :button="true" :button-between="false" snap="right" class="category-cards">
                <section class="cards-div" v-for="item in category.content" :key="item._id" @click="openContent(item)">
                    <CardObject :data="item" :click="openContent"></CardObject>
                </section>
            </vue-horizontal>
        </div>

        <v-dialog v-model="$route.query.content" id="details" fullscreen hide-overlay persistent transition="dialog-bottom-transition" class="details-container">
            <v-card v-if="selectedObject._id" style="background-color: #f2f2f3;">

                <div class="d-flex justify-end px-4 pt-4">
                    <v-btn icon color="primary" @click="$router.back()">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </div>

                <div style="max-width: 1200px; margin: auto;">
                    <div class="d-flex px-2 justify-center text-center">
                        <h1>{{ selectedObject.title }}</h1>
                    </div>

                    <div class="d-flex px-2 justify-center text-center">
                        <h4>{{ selectedObject.subtitle }}</h4>
                    </div>

                    <div class="d-flex flex-column mt-10 px-4" v-html="selectedObject.body">
                    </div>

                    <div v-if="$route.query.content && selectedObject.video" class="d-flex justify-center mt-6 px-4 pb-10">
                        <iframe style="border-radius: 8px;" :src="`https://player.vimeo.com/video/${selectedObject.video}`" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import VueHorizontal from "vue-horizontal";
import CardObject from "./CardObject.vue";

export default {
    name: "EducationContent",
    components: {
        CardObject,
        VueHorizontal
    },
    data: () => ({
        search: '',
        categories: [],
        items: [],
        heros: [],
        selectedObject: {},
        loaded: false,
    }),
    computed: {
        currentBreakpoint() {
            return this.$vuetify.breakpoint.width;
        },
        pageLoading: {
            get() {
                return this.$store.state.pageLoading;
            },
            set(value) {
                this.$store.commit('setPageLoading', value);
            },
        }
    },
    methods: {
        async setItems() {
            const resp = await this.$api.getVisibleEducationContent();

            if (resp.error) return;

            console.log(resp)

            resp.message.forEach(i => {
                if (i.featured && i.featured_image) this.heros.push(i);
            });

            this.items = resp.message;

            const resp2 = await this.$api.getAllEducationContentCategories();

            if (resp2.error) return

            let categories = resp2.message;

            categories.forEach(c => {
                c.content = resp.message.filter(item => item.category._id.toString() == c._id.toString());
            });

            this.categories = categories;
            this.loaded = true;
        },

        openContent(object) {
            this.selectedObject = object;
            if (!this.$route.query.content) this.$router.push({ path: `/central-educativa`, query: { content: object._id } });
            setTimeout(() => {
                //const div = document.getElementsByClassName('v-dialog--fullscreen');
                //div[0].scrollTop = 0
            }, 50);
        },

        async load() {
            await this.setItems();
        },
    },

    async mounted() {
        await this.load();
        if (this.$route.query && this.$route.query.content && !this.selectedObject?._id) {
            this.selectedObject = this.items.find(x => x._id.toString() === this.$route.query.content);
        }
    },
}
</script>

<style scoped>
.course {
    overflow: hidden;
}

.hero-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-start;
    color: white;
    padding-right: 5%;
    margin-left: 60%;
}

.hero-title {
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    text-shadow: 2px 2px 2px black;
}

.hero-description {
    margin-top: 15px;
    font-size: 18px;
    text-align: left;
    margin-bottom: 15px;
    text-shadow: 2px 2px 2px black;
}

.overlay {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.75) 45%, rgba(117, 19, 93, 0.0)) !important;
}

.section-title {
    font-size: 30px;
    font-weight: 600;
}

.hero-container {
    left: 0;
    right: 0;
    margin: auto;
}

.category-cards {
    margin-bottom: 30px
}

.cards-div {
    display: flex;
    flex-direction: line;
    align-content: center;
    align-items: center;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.horizontal-container {
    display: flex;
    overflow-x: hidden;
    margin-left: -12px;
}

.category-container {
    display: flex;
    flex-direction: row;
    padding-right: 6px;
    align-items: center;
}

.category-texts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex: 2;
}

.category-title {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-align: left;
}

.category-description {
    font-size: 16px;
    text-align: start;
}

.category-progress {
    margin-left: 10px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    flex: 1;
}

.category-progress-title {
    font-size: 20px;
    margin-right: 10px;
}

.category-progress-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.category-progress-percentage {
    font-size: 18px;
    font-weight: bold;
}

.category-progress-numbers {
    font-size: 14px;
}

.progress-circular {
    min-width: 80px;
}

.user-info-header {
    display: none;
    margin-bottom: 20px;
    margin-right: 10px;
}

.user-info-header-title {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.user-info-header-item {
    display: flex;
    flex-direction: column;
}

.user-info-header-item-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.user-info-header-score {
    font-size: 30px;
    font-weight: bold;
}

.divider {
    width: 90vw;
}

.mobile-title {
    font-size: 24px;
    font-weight: bold;
}

.rocket-icon {
    font-size: 38px;
    margin-right: 10px;
}

@media screen and (max-width: 1300px) {
    .hero-title {
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        justify-content: flex-start;
    }

    .hero-description {
        margin-top: 15px;
        font-size: 14px;
        text-align: left;
        margin-bottom: 15px;
    }
}


@media screen and (max-width: 720px) {
    .hero-content-container {
        height: 100%;
        display: flex;
        margin-left: 16px;
        justify-content: flex-end;
        padding-bottom: 30px;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.97) 35%, rgba(117, 19, 93, 0.0)) !important;
    }

    .hero-title {
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px;
    }

    .hero-description {
        display: none;

    }

}

@media screen and (max-width: 959px) {

    .user-info-header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hero-container {
        padding-right: 3px;
    }

    .category-title {
        font-size: 26px;
        line-height: 30px;
    }

    .category-description {
        line-height: 20px;
        margin-top: 6px;
    }

    .category-progress-title {
        font-size: 16px;
        margin-right: 8px;
    }

    .category-progress-percentage {
        font-size: 16px;
    }

    .category-progress-numbers {
        font-size: 12px;
    }
}

@media screen and (max-width: 419px) {

    .user-info-header {
        display: flex;
    }

    .category-progress-title {
        display: none;
    }

    .hero-container {
        padding-right: 3px;
    }
}
</style>