<template>
    <div>
        <v-card class="mb-4">
            <v-card-text class="d-flex flex-wrap">
                <v-text-field v-model="search" label="Buscar" outlined hide-details="auto" dense clearable class="mb-4 mb-sm-0 max-w-sm-72" />

                <v-spacer class="d-none d-sm-block" />

                <v-btn color="primary" @click="dialog = true">
                    Nova categoria
                </v-btn>
            </v-card-text>
        </v-card>
        <v-card>
            <v-data-table no-data-text="Sem dados" :headers="headers" :items="filteredItems" :items-per-page="-1">
                <template #[`item.visible`]="{ item }">
                    {{ item.visible ? 'Sim' : 'Não' }}
                </template>

                <template #[`item.actions`]="{ item }">
                    <v-tooltip left>
                        <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon @click="payload = item; dialog = true;">
                                <v-icon medium color="primary">
                                    mdi-file-edit-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon @click="openDeleteDialog(item)">
                                <v-icon medium color="error">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Apagar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar categoria
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a categoria <b>{{object.title}}</b>? Essa operação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogDelete = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialogDelete = false; deleteItem(object)">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <education-content-category-dialog v-model="dialog" :edited-item="payload" @saved="getItems" />
    </div>
</template>

<script>
export default {
    name: "EditCategory",
    components: {
        EducationContentCategoryDialog: () => import('./EducationContentCategoryDialog.vue')
    },
    data: () => ({
        search: '',
        items: [],
        dialog: false,
        dialogDelete: false,
        saving: false,
        object: {},
        payload: {
            title: ""
        }
    }),
    computed: {
        headers() {
            return [
                { text: "Título", value: "title" },
                { text: "Descrição", value: "subtitle" },
                { text: "Importância", value: "importance" },
                { text: "", value: "actions", sortable: false, width: 120 },
            ]
        },
        filteredItems() {
            const searchFields = ['title']

            if (!this.search) {
                return this.items
            }

            return this.items.filter(item => {
                return searchFields.some(f => item[f]?.toString().toLowerCase().includes(this.search.toLowerCase()))
            })
        }
    },
    methods: {
        async getItems() {
            const response = await this.$api.getAllEducationContentCategories()

            if (response.error) return

            this.items = response.message
        },
        async load() {
            await this.getItems();
        },
        editItem(item) {
            this.payload = { ...item };

            this.dialog = true;
        },

        openDeleteDialog(item) {
            this.object = item;
            this.dialogDelete = true;
        },

        async deleteItem(object) {
            object.filed = true;
            const response = await this.$api.createOrUpdateEducationContentCategory(object);

            if (response.error) {
                return
            }

            this.$toast('success', "Categoria apagada com sucesso!");
            await this.getItems();
        }
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.payload = {
                    title: ""
                }
            }
        }
    },
    created() {
        this.load();
    },
}
</script>
