import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/global.css";
import VueApexCharts from "vue-apexcharts";
import vueVimeoPlayer from 'vue-vimeo-player';
import DatetimePicker from 'vuetify-datetime-picker';
import './plugins/globals';

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);
Vue.use(vueVimeoPlayer);
Vue.use(DatetimePicker);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
